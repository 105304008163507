import AnpfiffIcon from '../assets/pictograms/whistle.svg'
import TaktikIcon from '../assets/pictograms/taktik_Spielanalyse.svg'
import SpieltMitIcon from '../assets/pictograms/Footballer.svg'
import VereinskasseIcon from '../assets/pictograms/Sportoekonomie.svg'
import TeamplayerIcon from '../assets/pictograms/Unternehmensfuehrung.svg'
import AnalyseIcon from '../assets/pictograms/Analyse.svg'

export interface NavigationItem {
  label: string
  href: string
  imghref: string
}

const navigation: NavigationItem[] = [
  {
    label: 'anpfiff',
    href: '#anpfiff',
    imghref: AnpfiffIcon
  },
  {
    label: 'taktik',
    href: '#taktik',
    imghref: TaktikIcon
  },
  {
    label: 'analyse',
    href: '#analyse',
    imghref: AnalyseIcon
  },
  {
    label: 'spielt-mit',
    href: '#spielt-mit',
    imghref: SpieltMitIcon
  },
  {
    label: 'vereinskasse',
    href: '#vereinskasse',
    imghref: VereinskasseIcon
  },
  {
    label: 'teamplayer',
    href: '#teamplayer',
    imghref: TeamplayerIcon
  }
]

export default navigation
